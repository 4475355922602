import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/service/navigation.service';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {

  constructor(
    private navigationService: NavigationService,
  ) { }

  ngOnInit(): void {
  }

  onSideNaviCloseClick(): void {
    this.navigationService.toggleNavigation(false);
  }
}
