import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './error/page/not-found/not-found.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then( m => m.HomeModule) },
  { path: 'price', loadChildren: () => import('./price/price.module').then( m => m.PriceModule) },
  { path: 'service', loadChildren: () => import('./service/service.module').then( m => m.ServiceModule) },
  { path: 'about', loadChildren: () => import('./about/about.module').then( m => m.AboutModule) },
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then( m => m.FaqModule) },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
