<app-breadcrumb></app-breadcrumb>
<article
  class="layout"
  fxLayout="column"
  fxLayoutAlign="center center"
  [ngClass.lt-sm]="{'xs':true}"
>
  <div class="text" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <span>お探しのページは存在しません。</span>
    <span><a href="/">トップ</a>、またはメニューからお探しください。</span>
  </div>
</article>
