<nav fxLayout="column">
  <div class="action" fxLayout="row" fxLayoutAlign="end center">
    <span (click)="onSideNaviCloseClick()"><mat-icon>arrow_back_ios</mat-icon></span>
  </div>
  <div class="header">
    <a class="logo" href="/" fxFlex>
      <img src="/assets/img/logo_onochiro_textwrap.svg" alt="小野カイロプラクティック">
    </a>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <span>営業日 月～土 9:00～18:30 (最終受付18:30)</span>
  </div>
  <ul fxLayout="row wrap">
    <li fxFlex="33">
      <a href="/" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon fontSet="material-icons-outlined">home</mat-icon>
        <span>トップ</span>
      </a>
    </li>
    <li fxFlex="33">
      <a href="tel:0173-35-0131" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon fontSet="material-icons-outlined">phone</mat-icon>
        <span>電話予約</span>
      </a>
    </li>
    <li fxFlex="33">
      <a href="https://beauty.hotpepper.jp/kr/slnH000467455/" rel="noopener noreferrer" fxLayout="column" fxLayoutAlign="center center">
        <span class="icon icon--hotpepper"></span>
        <span>ホットペッパー</span>
      </a>
    </li>
    <li fxFlex="33">
      <a href="/price" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon fontSet="material-icons-outlined">table_view</mat-icon>
        <span>料金表</span>
      </a>
    </li>
    <li fxFlex="33">
      <a href="/service" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon fontSet="material-icons-outlined">accessibility_new</mat-icon>
        <span>施術案内</span>
      </a>
    </li>
    <li fxFlex="33">
      <a href="/about" fxLayout="column" fxLayoutAlign="center center">
        <mat-icon fontSet="material-icons-outlined">meeting_room</mat-icon>
        <span>当院紹介</span>
      </a>
    </li>
  </ul>
</nav>