import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material/material.module';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { SideNavigationComponent } from './component/side-navigation/side-navigation.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SideNavigationComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    SharedModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SideNavigationComponent,
  ],
})
export class LayoutModule { }
