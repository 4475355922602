<footer
  fxLayout="column"
  [ngClass.lt-sm]="{'layout xs sm':true}"
  [ngClass.lt-md]="{'layout sm':true}"
>
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-sm="center center"
  >
    <div fxLayout="column">
      <a class="logo" href="/">
        <img src="/assets/img/logo_onochiro_white.svg" alt="小野カイロプラクティック">
      </a>
      <div class="contact" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center center">
        <a class="phone" href="tel:0173-35-0131" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon fontSet="material-icons-outlined">phone</mat-icon>
          <span fxLayout="column" fxLayoutAlign="center center">
            <span class="phone__number">0173-35-0131</span>
            <span>(予約優先)</span>
          </span>
        </a>
        <a class="hotpepper" href="https://beauty.hotpepper.jp/kr/slnH000467455/" rel="noopener noreferrer">
          <img src="/assets/img/logo_hotpepper.svg" alt="ホットペッパー 予約">
        </a>
      </div>
    </div>
    <div class="sitemap" fxLayout="row">
      <ul>
        <li>
          <a href="/">トップ</a>
        </li>
        <li>
          <a href="/price">料金表</a>
        </li>
        <li>
          <a href="/service">施術案内</a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="/about">当院紹介</a>
        </li>
        <!--
        <li>
          <a href="/faq">よくある質問</a>
        </li>
        -->
      </ul>
    </div>
  </div>
  <div class="copyright" fxLayout="row" fxLayoutAlign="center center">
    <small fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center">
      <span>Copyright&copy; 小野カイロプラクティック,</span>
      <span>2021 All Rights Reserved.</span>
    </small>
  </div>
</footer>