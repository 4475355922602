<mat-drawer-container
  class="container"
  [ngClass.lt-sm]="{'layout xs sm md lg':true}"
  [ngClass.lt-md]="{'layout sm md lg':true}"
  [ngClass.lt-lg]="{'layout md lg':true}"
  [ngClass.gt-md]="{'layout lg':true}"
  autosize
>
  <div class="menu" fxHide.gt-xs="true" (click)="onSideNaviToggleClick()">
    <mat-icon fontSet="material-icons-outlined">apps</mat-icon>
  </div>
  <mat-drawer #drawer class="sidenav"  mode="over" autoFocus="false" [opened]="isOpenSideNavi" (openedChange)="onNaviOpenedChange($event)">
    <app-side-navigation></app-side-navigation>
  </mat-drawer>
  <app-header></app-header>
  <main>
    <router-outlet (activate)="onActivate()"></router-outlet>
  </main>
  <app-footer></app-footer>
</mat-drawer-container>
