import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class SharedService {

  constructor(
    private title: Title,
  ) { }

  public setPageTitle(title: string): void {
    this.title.setTitle(`小野カイロプラクティック | ${title}`);
  }
}
