import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from './shared/service/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  isOpenSideNavi: boolean;

  private subscription: Subscription;

  constructor(
    private navigationService: NavigationService,
  ) {
    this.subscription = new Subscription();
    this.isOpenSideNavi = false;
  }

  ngOnInit(): void {
    this.subscription.add(this.navigationService.navigationStatus.subscribe(isOpen => {
      this.isOpenSideNavi = isOpen;
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSideNaviToggleClick(): void {
    this.navigationService.toggleNavigation(!this.navigationService.navigationStatus.value);
  }

  onNaviOpenedChange(changed: boolean): void {
    if (changed !== this.isOpenSideNavi) {
      this.navigationService.toggleNavigation(changed);
    }
  }

  onActivate(): void {
    window.scroll(0, 0);
  }
}
