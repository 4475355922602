import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavigationService {

  private localNavigationStatus: BehaviorSubject<boolean>;

  constructor() {
    this.localNavigationStatus = new BehaviorSubject<boolean>(false);
  }

  public toggleNavigation(isOpen: boolean): void {
    this.localNavigationStatus.next(isOpen);
  }

  get navigationStatus(): BehaviorSubject<boolean> {
    return this.localNavigationStatus;
  }
}
