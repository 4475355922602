import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { labels as PathLables } from 'src/config/path-label';
import { Breadcrumb } from '../../model/breadcrumb.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: Breadcrumb[];

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    this.breadcrumbs = [];
  }

  ngOnInit(): void {
    const paths: string[] = [];

    this.breadcrumbs = [];
    this.activatedRoute.snapshot.pathFromRoot.forEach((ars) => {
      ars.url.forEach((url) => {
        if (url.path in PathLables) {
          paths.push(url.path);
          this.breadcrumbs.push({
            path: '/' + paths.join('/'),
            label: PathLables[url.path],
          });
        }
      });
    });
  }
}
