<section>
  <ul class="breadcrumbs" fxLayout="row" fxLayoutAlign="start center">
    <li>
      <a href="/" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon fontSet="material-icons-outlined">home</mat-icon>
        <span>トップ</span>
      </a>
    </li>
    <li *ngFor="let breadcrumb of breadcrumbs; last as isLast" [class.disabled]="isLast">
      <span class="disabled" *ngIf="isLast; else link">{{ breadcrumb.label }}</span>
      <ng-template #link>
        <a [href]="breadcrumb.path">{{ breadcrumb.label }}</a>
      </ng-template>
    </li>
  </ul>
</section>