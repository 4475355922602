<header
  fxLayout="column"
  fxLayoutAlign="start center"
  [ngClass.lt-sm]="{'layout xs':true}"
>
  <div class="content" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center center">
    <a class="logo" href="/">
      <img src="/assets/img/logo_onochiro.svg" alt="小野カイロプラクティック">
    </a>
    <div class="contact" fxLayout="row" fxLayoutAlign="end center" fxFlex>
      <a class="phone" href="tel:0173-35-0131" fxLayout="row" fxLayoutAlign="center center" fxFlex.lt-md="50">
        <mat-icon fontSet="material-icons-outlined">phone</mat-icon>
        <span fxLayout="column" fxLayoutAlign="center center">
          <span class="phone__number">0173-35-0131</span>
          <span>(予約優先)</span>
        </span>
      </a>
      <a class="hotpepper" href="https://beauty.hotpepper.jp/kr/slnH000467455/" rel="noopener noreferrer" fxFlex.lt-md="50">
        <img src="/assets/img/logo_hotpepper.svg" alt="ホットペッパー 予約">
      </a>
    </div>
  </div>
  <nav fxHide.lt-sm="true">
    <ul fxLayout="row" fxLayoutAlign="center center">
      <li>
        <a href="/" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon fontSet="material-icons-outlined">home</mat-icon>
          <span>トップ</span>
        </a>
      </li>
      <li>
        <a href="/price" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon fontSet="material-icons-outlined">table_view</mat-icon>
          <span>料金</span>
        </a>
      </li>
      <li>
        <a href="/service" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon fontSet="material-icons-outlined">accessibility_new</mat-icon>
          <span>施術案内</span>
        </a>
      </li>
      <li>
        <a href="/about" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon fontSet="material-icons-outlined">meeting_room</mat-icon>
          <span>当院紹介</span>
        </a>
      </li>
    </ul>
  </nav>
</header>