import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationService } from './service/navigation.service';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material/material.module';
import { SharedService } from './service/shared.service';



@NgModule({
  declarations: [BreadcrumbComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
  ],
  exports: [
    BreadcrumbComponent,
  ],
  providers: [
    NavigationService,
    SharedService,
  ],
})
export class SharedModule { }
